<template>
  <div class="app-container">
    <el-card shadow="never">
      <div class="-mb-16px">
        <el-form
          ref="searchRefs"
          :model="params"
          size="small"
          label-width="70px"
          inline
        >
          <el-form-item label="套餐标题" prop="title">
            <el-input
              v-model="params.title"
              placeholder="请输入套餐标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="订单号" prop="order_sn">
            <el-input
              v-model="params.order_sn"
              placeholder="请输入订单号"
            ></el-input>
          </el-form-item>
          <el-form-item label="购买方式" prop="type">
            <el-select v-model="params.type" placeholder="请选择购买方式">
              <el-option label="全部" value=""></el-option>
              <el-option label="自主购买" value="1"></el-option>
              <el-option label="自动续费" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="支付状态" prop="status">
            <el-select v-model="params.status" placeholder="请选择支付状态">
              <el-option label="全部" value=""></el-option>
              <el-option label="待支付" value="1"></el-option>
              <el-option label="支付成功" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="ml-6px">
            <el-button type="primary" @click="getList(1)">查询</el-button>
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <el-card class="mt-16px" v-loading="pager.loading" shadow="never">
      <div></div>
      <div class="mt-16px">
        <el-table :data="pager.list">
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column label="订单编号" width="180">
            <template v-slot="scope">
              <span> {{ scope.row.order_sn }} </span>
            </template>
          </el-table-column>
          <el-table-column
            label="套餐名称"
            min-width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="scope">
              <div>
                <div>{{ scope.row.title }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="套餐金额" width="120">
            <template v-slot="scope">
              <span> {{ scope.row.money }} </span>
            </template>
          </el-table-column>
          <el-table-column label="购买方式" width="120">
            <template v-slot="scope">
              <span v-if="scope.row.type === '1'"> 自主购买 </span>
              <span v-else-if="scope.row.type === '2'"> 自动续费 </span>
            </template>
          </el-table-column>
          <el-table-column label="授权天数" width="120">
            <template v-slot="scope">
              <span> {{ scope.row.days }} </span>
            </template>
          </el-table-column>
          <el-table-column label="支付状态" width="80">
            <template v-slot="scope">
              <el-tag size="small" type="default" v-if="scope.row.status === 1"
                >待支付</el-tag
              >
              <el-tag
                size="small"
                type="success"
                v-else-if="scope.row.status === 2"
                >支付成功</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="购买时间" width="160">
            <template v-slot="scope">
              <span>
                {{ formatDate(scope.row.create_time, "yyyy-mm-dd hh:MM:ss") }}
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-between mt-16px">
        <div class="flex"></div>
        <pagination
          v-model="pager"
          :pageSizes="[10, 20, 30]"
          @change="getList()"
        ></pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import Pagination from "@/components/pagination";
import { Pager } from "@/utils/pager";

import { apiListRenew } from "@/api/trade";
import { formatDate } from "@/utils/util";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      params: {
        title: "",
        order_sn: "",
        type: "",
        status: "",
      },
      pager: new Pager(),
    };
  },
  created() {
    this.getList();
  },
  methods: {
    formatDate,
    onReset() {
      this.$refs.searchRefs.resetFields();
      this.getList(1);
    },
    getList(page) {
      page && (this.pager.page = page);
      this.pager.request({
        callback: apiListRenew,
        params: {
          ...this.params,
        },
      });
    },
  },
};
</script>
