export class Pager {
  _page = 1;
  _size = 10;
  _loading = false;
  _count = 0;
  _list = [];
  constructor(payload) {
    if (payload?.page) {
      this._page = payload.page;
    }
    if (payload?.size) {
      this._size = payload.size;
    }
  }
  request(payload) {
    if (this._loading) {
      return Promise.reject();
    }
    this._loading = true;
    return payload
      .callback({
        page: this._page,
        size: this._size,
        ...payload.params,
      })
      .then((resp) => {
        // console.log(resp);
        this._count = resp?.count;
        this._list = resp?.list;
        return Promise.resolve(resp);
      })
      .catch((error) => {
        return Promise.reject(error);
      })
      .finally(() => {
        this._loading = false;
      });
  }
  get loading() {
    return this._loading;
  }
  set loading(loading) {
    this._loading = loading;
  }
  get page() {
    return this._page;
  }
  set page(page) {
    this._page = page;
  }
  get size() {
    return this._size;
  }
  set size(size) {
    this._size = size;
  }
  get count() {
    return this._count;
  }
  get list() {
    return this._list;
  }
}
