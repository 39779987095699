<template>
  <div class="pagination">
    <el-pagination
      :current-page="value.page"
      :page-sizes="pageSizes"
      :page-size="value.size"
      :layout="layout"
      :total="value.count"
      @current-change="pageChange"
      @size-change="sizeChange"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    pageSizes: {
      // 页数列表
      type: Array,
      default: () => [10, 20, 30],
    },
    layout: {
      // 分页布局
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
  },
  methods: {
    // 页数改变
    sizeChange(size) {
      this.value.page = 1;
      this.value.size = size;
      this.$emit("change", this.value);
    },
    // 页码改变
    pageChange(page) {
      this.value.page = page;
      this.$emit("change", this.value);
    },
  },
};
</script>
