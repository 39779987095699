import request from "@/utils/request";

export function apiListCombo(params) {
  return request.get("/combo/list", params);
}

export function apiListRecharge(params) {
  return request.get("/trade/recharge", params);
}

export function apiListRenew(params) {
  return request.get("/trade/renew", params);
}
